@use '@angular/material' as mat;

$md-primary: (
    50: #ffe8e3,
    100: #ffc5b9,
    200: #ff9f8b,
    300: #ff785d,
    400: #ff5b3a,
    500: #ff3e17,
    600: #ff3814,
    700: #ff3011,
    800: #ff280d,
    900: #ff1b07,
    A100: #ffffff,
    A200: #fff5f4,
    A400: #ffc4c1,
    A700: #ffaca7,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-accent: (
    50: #eef8e4,
    100: #d6edbb,
    200: #bae28d,
    300: #9ed65f,
    400: #8acd3d,
    500: #75c41b,
    600: #6dbe18,
    700: #62b614,
    800: #58af10,
    900: #45a208,
    A100: #dfffce,
    A200: #bdff9b,
    A400: #9cff68,
    A700: #8bff4e,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$md-warn: (
    50: #fef5e1,
    100: #fde6b3,
    200: #fcd581,
    300: #fbc44e,
    400: #fab828,
    500: #f9ab02,
    600: #f8a402,
    700: #f79a01,
    800: #f69101,
    900: #f58001,
    A100: #ffffff,
    A200: #fff3e8,
    A400: #ffd8b5,
    A700: #ffca9c,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$fe-energy-label-primary: mat.define-palette($md-primary);
$fe-energy-label-accent: mat.define-palette($md-accent);
$fe-energy-label-warn: mat.define-palette($md-warn);

$fe-energy-label-theme: mat.define-light-theme(
    (
        color: (
            primary: $fe-energy-label-primary,
            accent: $fe-energy-label-accent,
            warn: $fe-energy-label-warn,
        ),
    )
);

$color-red: mat.get-color-from-palette($fe-energy-label-primary);

$color-black: map-get($md-primary, 900);
$color-white: #ffffff;
$color-maintenance: #000;
