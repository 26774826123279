@use '@angular/material' as mat;
@import './bootstrap.scss';
@import './colors';
@import '../assets/fonts/inter';
@import '../assets/fonts/material-icons';
@import '@vi-succ/design-system-web/styles/dsw.css';

// TODO: add styles only for used components
@include mat.all-component-themes($fe-energy-label-theme);

$typography-config: mat.define-typography-config(
    $font-family: 'Inter',
    $body-1: mat.define-typography-level(inherit, 1.2),
);

@include mat.all-component-typographies($typography-config);
@include mat.core();

html,
body {
    height: 100%;
}
body {
    margin: 0;
    background-color: #fafafa !important;
}

.semi-bold-text {
    font-weight: 600;
    color: black;
}

.medium-text {
    font-weight: 500;
    color: black;
}

.regular-text {
    font-weight: 400;
    color: black;
}

.p-16 {
    font-size: 16px;
}

.h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.highlight {
    color: $color-red;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.btnspinner:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid white;
    border-top-color: black;
    animation: spinner 0.8s linear infinite;
}

button.search-receipt.mdc-button {
    background-color: grey;
}

@import './mat-components/button';

.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
    letter-spacing: 0 !important;
}

.mat-mdc-tab {
    .mdc-tab__text-label {
        letter-spacing: 0 !important;
    }
    .mat-mdc-tab-ripple,
    .mdc-tab__ripple {
        display: none !important;
    }
}
